<template>
  <div
    class="md:h-90 md:w-90 custom-scrollbar fixed inset-0 z-50 overflow-x-scroll rounded-lg bg-white-100 p-7 shadow-drop-6 md:absolute md:bottom-auto md:left-auto md:top-full"
  >
    <div
      v-for="item in playlist"
      :key="`playlist-${item.id}`"
      class="text-small mb-4 flex cursor-pointer leading-4 last:mb-0"
      :class="
        item.id === currentlyPlayingId ? 'text-black-100' : 'text-black-32'
      "
      @click="handleClick(item.id)"
    >
      <span class="w-1/12"
        ><SvgIcon name="play-circle" class="icons-small"
      /></span>
      <span class="w-11/12">{{ item.label }}</span>
    </div>
  </div>
</template>

<script setup>
defineProps({
  playlist: {
    type: Array,
    default: () => [],
  },

  currentlyPlayingId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['update:currentlyPlayingId']);

const handleClick = (id) => {
  emit('update:currentlyPlayingId', id);
};
/*
export default {
  props: {
    playlist: {
      type: Array,
      default: () => [],
      require: true,
    },

    currentlyPlayingId: {
      type: Number,
      default: null,
    },
  },

  methods: {
    handleClick(id) {
      this.$emit('update:currentlyPlayingId', id);
    },
  },
};*/
</script>

<style lang="scss"></style>
